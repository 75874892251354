<template lang="html">
  <v-card flat>
    <v-toolbar flat color="info">
        <v-toolbar-title>Create A New Theme</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
          <form>
            <v-row>
              <v-col cols="12" md="8" offset-md="2">
                <v-text-field
                  name="themeName"
                  label="Theme Name"
                  id="themeName"
                  v-model="themeName"
                  color="secondary"
                  type="text"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" md="8" offset-md="2">
                <v-text-field
                  name="themeDesc"
                  label="Theme Description"
                  id="themDesc"
                  v-model="themeDesc"
                  color="secondary"
                  type="text"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8" offset-md="2">
                <v-textarea
                  name="themeNotes"
                  label="Theme Notes"
                  id="themeNotes"
                  v-model="themeNotes"
                  color="secondary"
                  placeholder="Make notes about the theme, certain hero cosmetics, etc."
                  multi-line
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutter>
              <v-col cols="12" md="4" class="subtitle-1 text-center">
                <v-icon left color="red" large>far fa-hand-rock</v-icon>
                    Strength Heroes
                  <hr>
                  <v-checkbox v-for="hero in getStrHeroes" :key="hero.name" :label="hero.name" :value="hero.name" v-model="selectedHeroes" class="pl-1" color="secondary">
                  </v-checkbox>
              </v-col>
              <v-col cols="12" md="4" class="info subtitle-1 text-center">
                <v-icon left color="green" large>fab fa-google-wallet</v-icon>
                    Agility Heroes
                <hr>
                <v-checkbox v-for="hero in getAgiHeroes" :key="hero.name" :label="hero.name" :value="hero.name" v-model="selectedHeroes" class="pl-1" color="secondary">
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="4" class="subtitle-1 text-center">
                    <v-icon left color="blue" large>fas fa-magic</v-icon>
                    Int Heroes
                  <hr>
                  <v-checkbox v-for="hero in getIntHeroes" :key="hero.name" :label="hero.name" :value="hero.name" v-model="selectedHeroes" class="pl-1" color="secondary">
                  </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-alert type ="error" :value="!validateTheme">
                        ***Themes must have a <b>NAME</b>, <b>DESCRIPTION</b> and a <b>MINIMUM</b> of <b>FIVE</b> heroes selected to be submitted.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row no-gutter>
                <v-col cols="12" md="6" class="text-center">
                    <v-btn large color="secondary" outlined @click="clearForm">
                        <v-icon left>fas fa-redo</v-icon>
                        Reset Form
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                    <v-btn large outlined color="secondary" @click="submitTheme()" :disabled="!validateTheme">
                        <v-icon left>fas fa-share-square</v-icon>
                        Submit Theme
                    </v-btn>
                </v-col>
            </v-row>
          </form>
      </v-col>
    </v-row>
    <!-- PENDING DIALOG -->
    <v-dialog persistent max-width="500" v-model="dialog">
        <v-card>
            <v-toolbar color="secondary" dark flat>
                <v-toolbar-title>Just so you know...</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row class="subtitle-1">
                        <v-col cols="12" >
                            To assist in the integrity of our database and community all newly submitted themes are personally reviewed by DotaTheme admins.
                        </v-col>
                        <v-col cols="12">
                            If you have questions contact us in <a href="https://discord.gg/0h2DOuBCUWs9XHZp" target="_blank" class="secondary--text">Discord</a>.</h6>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12" class="text-center text-md-right">
                        <v-btn color="secondary" outlined @click="dialog = !dialog">
                            <v-icon left>fas fa-check</v-icon>
                            Got It!
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      themeName: "",
      themeDesc: "",
      themeNotes: "",
      selectedHeroes: []
    }
  },
  created: function () {
    
  },
  computed: {
    getAgiHeroes() {
        return this.$store.getters.getHeroes.filter(hero => hero.attribute == "Agility").sort((a, b) => {
            if (a.name > b.name){
                return 1
            }
            else {
                return -1
            }
        })
    },
    getIntHeroes(){
        return this.$store.getters.getHeroes.filter(hero => hero.attribute == "Intelligence").sort((a, b) => {
            if (a.name > b.name){
                return 1
            }
            else {
                return -1
            }
        })
    },
    getStrHeroes(){
      return this.$store.getters.getHeroes.filter(hero => hero.attribute == "Strength").sort((a, b) => {
            if (a.name > b.name){
                return 1
            }
            else {
                return -1
            }
      })
    },
    validateTheme () {
      return this.themeName != "" && this.themeDesc !== "" && this.selectedHeroes.length >= 5
    }
  },
  methods: {
    clearForm () {
      this.themeName = '',
      this.themeDesc = '',
      this.selectedHeroes = null
    },
    submitTheme () {
      const themeInfo = {
        themeName: this.themeName,
        themeDesc: this.themeDesc,
        selectedHeroes: this.selectedHeroes,
        themeNotes: this.themeNotes,
        themeApproved: "pending"
      }
      this.$store.dispatch('createTheme', themeInfo);
    }
  }
}
</script>
